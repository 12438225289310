import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultOrderData = {
  id: undefined,
  orderReference: '',
  customerIP: '',
  status: '',
  orderedBy: '',
  comments: '',
  total: 0,
  shippingCharges: 0,
  taxedAmount: 0,
  discountAmount: 0,
  amountReceived: 0,
  couponId: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: '',
  invoiceAttachment: null,
  transportBilty: null,
  otherAttachment: null,
  userId: 0,
  user: {
    id: null
  },
  addressId: 0,
  address: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  transporterId: 0,
  transporter: {
    id: null
  },
  shippingId: 0,
  shippingAddress: {
    id: null
  }
};
export var getOrders = function getOrders(params) {
  return request({
    url: '/orders',
    method: 'get',
    params: params
  });
};
export var getOrderById = function getOrderById(id) {
  return request({
    url: "/orders/".concat(id),
    method: 'get'
  });
};
export var updateOrder = function updateOrder(id, data) {
  return request({
    url: "/orders/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteOrder = function deleteOrder(id) {
  return request({
    url: "/orders/".concat(id),
    method: 'delete'
  });
};
export var createOrder = function createOrder(data) {
  return request({
    url: '/orders/',
    method: 'post',
    data: data
  });
};
export var orderCount = function orderCount(data) {
  return request({
    url: '/orders/count',
    method: 'post',
    data: data
  });
};
export var getStatusCount = function getStatusCount(params) {
  return request({
    url: '/orders/count/status',
    method: 'get',
    params: params
  });
};
export var orderExport = function orderExport(data) {
  return request({
    url: '/orders/export',
    method: 'post',
    data: data
  });
};
export var confirmOrder = function confirmOrder() {
  var orderId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return request({
    url: '/transactions/update-order/' + orderId,
    method: 'get'
  });
};
export var getOrderInfo = function getOrderInfo(id) {
  return request({
    url: "/orders/info/".concat(id),
    method: 'get'
  });
};
export var getOrdersProducts = function getOrdersProducts(params) {
  return request({
    url: '/orders-products',
    method: 'get',
    params: params
  });
};
export var manageOrdersProducts = function manageOrdersProducts(data) {
  return request({
    url: '/orders-products/bulk-add',
    method: 'post',
    data: data
  });
};
export var manageRating = function manageRating(orderId, data) {
  return request({
    url: "/orders-products/manage-rating/".concat(orderId),
    method: 'patch',
    data: data
  });
};
export var pendingPayment = function pendingPayment(userId) {
  return request({
    url: "/orders/pending-payment/".concat(userId),
    method: 'get'
  });
};
export var getDispatchList = function getDispatchList(data) {
  return request({
    url: '/orders-products/stats',
    method: 'post',
    data: data
  });
};
export var getDispatchProducts = function getDispatchProducts(id) {
  return request({
    url: "/orders-products/pending-dispatch/".concat(id),
    method: 'get'
  });
};
export var deleteOrdersProduct = function deleteOrdersProduct(id) {
  return request({
    url: "/orders-products/".concat(id),
    method: 'delete'
  });
};